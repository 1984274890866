const menuData = {
    principal: [
        {
            nav: 'Accueil',
            url: 'accueil'
        },
        {
            nav: 'Admin',
            url: 'admin/agenda',
        }
    ],
    admin: [
        {
            nav: 'Agenda',
            url: 'agenda',
            icon: '/img/icon/agenda.png',
        },
        {
            nav: 'À valider',
            url: 'valider',
            icon: '/img/icon/validate.png',
        },
        {
            nav: 'Ajouter',
            url: 'ajouter',
            icon: '/img/icon/plus.png',
        },
    ],
    add: [
        {
            nav: 'Catégorie',
            url: 'categorie',
            icon: '/img/icon/category.png',
        },
        {
            nav: 'Véhicule',
            url: 'vehicule',
            icon: '/img/icon/minibus.png',
        },
    ],
    footer: [
        {
            nav: 'Mentions Légales',
            url: 'mentions'
        },
        {
            nav: 'Cookies',
            url: 'cookies',
        },
    ],
}

export default menuData