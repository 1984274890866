import React from 'react';

const h = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
};

const Title = ({ name, label }) => {
    const FieldComponent = h[name]

    return (
        <section>
            <FieldComponent>{label}</FieldComponent>
        </section>
    );
};

export default Title;
