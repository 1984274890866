import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// Components
import { AuthContext } from './AuthProvider';
import { AlertContext } from './AlertContext';
import Form from './Form';
// Api
import { createData } from '../api/api'

const Create = ({ fields, what, message = 'Ajouté avec succès', title = 'Mettre un title' }) => {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const { openModal } = useContext(AlertContext)

    const handleFormSubmit = async (data) => {
        try {
            let formData;
            let headers;

            if (hasFiles(data)) {
                formData = new FormData();
                Object.entries(data).forEach(([key, value]) => {
                    if (value instanceof FileList && value.length > 0) {
                        for (let i = 0; i < value.length; i++) {
                            formData.append(key, value[i]);
                        }
                    } else if (value !== null && value !== undefined) {
                        formData.append(key, value);
                    }
                });

                headers = {};
            } else {
                formData = JSON.stringify(data);
                headers = {
                    'Content-Type': 'application/json'
                };
            }

            const response = await createData(headers, formData, what);
            if (response.ok) {
                const responseData = await response.json();
                if (responseData.token) {
                    localStorage.setItem("token", responseData.token);
                    navigate('/')
                    login(responseData.token);
                }
                else {
                    navigate('/admin/ajouter')
                }
                openModal(message);
            } else {
                const responseData = await response.json();
                openModal(responseData.error, 'error');
                throw new Error(`Une erreur s'est produite lors de la création de ${what}`);
            }
        } catch (error) {
            console.error(`Erreur lors de la création de ${what}:`, error);
        }
    };

    const hasFiles = (data) => {
        for (const key in data) {
            const value = data[key];
            if (value instanceof FileList && value.length > 0) {
                return true;
            }
        }
        return false;
    };

    return (
        <section className='flex fdc'>
            <h2>{title}</h2>
            <Form fields={fields} onSubmit={handleSubmit(handleFormSubmit)} register={register} errors={errors} />
        </section>
    );
};

export default Create;