import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
// Components
import { AuthContext } from './AuthProvider'
import DeleteButton from './Action/DeleteButton'
import UpdateButton from './Action/UpdateButton'
import Image from './Image'
// Api
import { getData } from '../api/api'
// Style
import '../styles/Categories.css'

const Categories = () => {
    const [data, setData] = useState([])
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await getData('categories')
                setData(responseData)
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la récupération des données:', error)
            }
        }
        fetchData()
    }, [])

    const handleDelete = (id) => {
        setData((prevData) => prevData.filter((item) => item.id !== id))
    }

    return (
        <>
            {data.length > 0 &&
                <>
                    <h2 className='title-categories'>... Ou par catégories</h2>
                    <section id='categories' className='categories list flex'>
                        {data.map((d) =>
                            <article key={d.id} className='flex'>
                                <Link to={`/categorie/${d.id}`} className='flex'>
                                    <section className='content-categorie flex'>
                                        <picture>
                                            <Image imageUrl={d.iwmageUrl} />
                                        </picture>
                                        <section className='flex'>
                                            <p>{d.name}</p>
                                        </section>
                                    </section>
                                </Link>
                                {user && user.admin ?
                                    <aside>
                                        <UpdateButton id={d.id} what={'categories'} />
                                        <DeleteButton id={d.id} onDelete={handleDelete} what={'categories'} />
                                    </aside>
                                    : ''}
                            </article>
                        )}
                    </section>
                </>
            }
        </>
    )
}

export default Categories