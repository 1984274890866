import { useState } from "react"

export default function File({ name, type, label, children, register, validation, errors }) {
    const [files, setFiles] = useState('')

    const handleFile = (e) => {
        setFiles(e.target.files)
    }

    return (
        <>
            <label htmlFor={name}>
                {label}
                <input type={type} id={name} onInput={handleFile} {...register(name, validation)} {...children} />
                <p className="pointer">Sélectionner un fichier</p>
            </label>
            <p role="alert">{errors[name] && errors[name].message}</p>
            {files &&
                Object.values(files).map((f, i) => (
                    <article key={i} className="files flex">
                        <img src={URL.createObjectURL(f)} alt="" />
                        <p>{f.name}</p>
                    </article>
                ))
            }
        </>
    )
}