import { Outlet } from "react-router-dom";
// Components
import Nav from "./Nav";
// Style
import '../styles/Add.css'

const Add = () => {
    return (
        <section className="add admin flex">
            <Nav what={'add'} />
            <Outlet />
        </section>
    );
};

export default Add;