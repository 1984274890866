import { createContext, useState, useEffect } from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]);

    const openModal = (message, type) => {
        const newAlert = { message, type };
        setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
    };

    const closeModal = (i) => {
        setAlerts((prevAlerts) => {
            const newAlerts = [...prevAlerts];
            newAlerts.splice(i, 1);
            return newAlerts;
        });
    };

    useEffect(() => {
        if (alerts.length > 0) {
            const timeoutId = setTimeout(() => {
                closeModal();
            }, 4000);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [alerts]);

    const contextValue = {
        alerts,
        openModal,
        closeModal,
    };

    return (
        <AlertContext.Provider value={contextValue}>
            {children}
        </AlertContext.Provider>
    );
};
