import { useContext, useState } from 'react';
// Components
import ConfirmationModal from '../ConfirmationModal';
import { deleteAction } from '../../api/api';
import { AlertContext } from '../AlertContext';

const DeleteButton = ({ id, event, onDelete, what, children = 'Supprimer', message = 'Supprimé avec succès' }) => {
    const [showModal, setShowModal] = useState(false);
    const { openModal } = useContext(AlertContext)

    const handleDelete = async () => {
        try {
            const success = await deleteAction(what, id);
            if (success) {
                onDelete(id, event);
                openModal(`${message}`);
            }
        } catch (error) {
            console.error('Erreur lors de la suppression', error);
        }
    };

    const handleConfirmModal = () => {
        handleDelete();
        setShowModal(false);
    };

    const handleCancelModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <button onClick={() => setShowModal(true)} aria-label={`Supprimer ${what} ${id}`} className='pointer'>{children}</button>
            {showModal && (
                <ConfirmationModal
                    onConfirm={handleConfirmModal}
                    onCancel={handleCancelModal}
                    message={`Voulez-vous vraiment supprimer ?`}
                />
            )}
        </>
    );
};

export default DeleteButton;