import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
// Components
import Form from "../Form";
import { AlertContext } from "../AlertContext";
// Data
import formData from "../../data/FormData";
// Api
import { createData } from "../../api/api";
// Styles
import '../../styles/ByDate.css'

const ByDate = ({ onFilterHandle, reset }) => {
    const { handleSubmit, register, formState: { errors }, setValue } = useForm();
    const date = localStorage.getItem('filter')
    const { openModal } = useContext(AlertContext)

    const handleFormSubmit = async (data) => {
        setValue('date_debut', data.date_debut)
        setValue('date_fin', data.date_fin)
        const date = {
            date_debut: data.date_debut,
            date_fin: data.date_fin,
        }
        localStorage.setItem('filter', JSON.stringify(date))

        try {
            const response = await createData(
                { 'Content-Type': 'application/json' }, 
                JSON.stringify(data), 
                'vehicules/byDate'
            );
            const responseJ = await response.json();

            if (response.ok) {
                onFilterHandle(responseJ);
            } else {
                resetFilter()
                openModal(responseJ.error, 'error')
            }
        } catch (error) {
            console.error(`Erreur lors de la récupération des véhicules :`, error);
        }
    }

    useEffect(() => {
        date && handleFormSubmit(JSON.parse(date))
    }, [])

    const resetFilter = () => {
        reset()
        formData.byDate.map((bd) => 
            setValue(bd.name, null)
        )
    }

    return (
        <section className="bydate flex fdc">
            <Form fields={formData.byDate} onSubmit={handleSubmit(handleFormSubmit)} register={register} errors={errors} />
            {date && 
                <span className="filter flex" onClick={resetFilter} >
                    Filtré par :
                    <button className="pointer flex">
                        <img src="img/icon/x-mark.png" alt="Supprimer le filtre" className="cross" /> Date
                    </button>
                </span>
            }
        </section>
    );
};

export default ByDate;