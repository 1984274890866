import CryptoJS from 'crypto-js';

const API_BASE_URL = '/api';
// const API_BASE_URL = 'http://localhost:3000/api';

export async function fetchEventsByVehiculesId(vehiculesId) {
    try {
        const response = await fetch(`${API_BASE_URL}/events/vehicules/${vehiculesId}`);
        const responseData = await response.json();
        const localStorageEvent = localStorage.getItem('event');
        const idUrl = new URLSearchParams(window.location.search).get('re')

        const formattedEvents = responseData
            .filter(event => {
                if (event.status > 0) {
                    return true;
                }
                if (localStorageEvent) {
                    const decryptedEventId = CryptoJS.AES.decrypt(localStorageEvent, '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString(CryptoJS.enc.Utf8);
                    return event.id === decryptedEventId
                }
                else if (idUrl === event.id) {
                    return true
                }
                return false
            })
            .map(event => ({
                id: event.id,
                // title: (user && user.admin ? event.name : '') || (event.id === CryptoJS.AES.decrypt(localStorageEvent, '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString(CryptoJS.enc.Utf8) ? event.username : ''),
                title: event.name,
                start: event.dateLocation,
                end: event.dateRestitution,
                classNames: [event.status > 0 ? 'validate' : 'waiting'],
                // display: 'background'
            }));
        return formattedEvents;
    } catch (error) {
        throw new Error('Une erreur s\'est produite lors de la récupération des données:', error);
    }
}

export async function updateEvent(eventData) {
    try {
        const TOKEN = localStorage.getItem("token") ?? '';
        const EVENTID = localStorage.getItem("event") ?? '';
        let AUTHORIZATION = {}
        if (TOKEN) {
            AUTHORIZATION = { 'Authorization': `Bearer ${TOKEN}` }
        }

        const response = await fetch(`${API_BASE_URL}/events/drag/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...AUTHORIZATION,
                'X-Event-Id': EVENTID,
            },
            body: JSON.stringify(eventData)
        });

        if (response.ok) {
            return response
        } else {
            throw new Error('Une erreur s\'est produite lors de la mise à jour des informations de l\'événement');
        }
    } catch (error) {
        throw new Error('Erreur lors de la mise à jour des informations de l\'événement:', error);
    }
}

export async function deleteAction(what, id) {
    try {
        const TOKEN = localStorage.getItem("token") ?? '';
        const EVENTID = localStorage.getItem("event") ?? '';
        let AUTHORIZATION = {}
        if (TOKEN) {
            AUTHORIZATION = { 'Authorization': `Bearer ${TOKEN}` }
        }

        const response = await fetch(`${API_BASE_URL}/${what}/${id}`, {
            method: 'DELETE',
            headers: {
                ...AUTHORIZATION,
                'X-Event-Id': `${EVENTID}`,
            },
        });
        if (response.ok) {
            return true;
        } else {
            throw new Error(`Une erreur s'est produite lors de la suppression de la catégorie`);
        }
    } catch (error) {
        console.error('Erreur lors de la suppression :', error);
        throw error;
    }
}

export async function agenda(date) {
    try {
        const TOKEN = localStorage.getItem("token") ?? '';
        let AUTHORIZATION = {}
        if (TOKEN) {
            AUTHORIZATION = { 'Authorization': `Bearer ${TOKEN}` }
        }

        const response = await fetch(`${API_BASE_URL}/events/agenda/date`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...AUTHORIZATION,
            },
            body: JSON.stringify({ selectedDate: date })
        });
        if (response.ok) {
            const responseData = await response.json();
            return responseData;
        } else {
            throw new Error('Une erreur s\'est produite lors de la récupération des données');
        }
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données:', error)
        throw error;
    }
}

export async function getData(what) {
    try {
        const response = await fetch(`${API_BASE_URL}/${what}`);
        if (response.ok) {
            const responseData = await response.json();
            return responseData;
        } else {
            throw new Error('Une erreur s\'est produite lors de la récupération des données');
        }
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données:', error)
        throw error;
    }
}

export async function getDataById(what, id) {
    try {
        const response = await fetch(`${API_BASE_URL}/${what}/${id}`);
        if (response.ok) {
            const responseData = await response.json();
            return responseData;
        } else {
            throw new Error('Une erreur s\'est produite lors de la récupération des données');
        }
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données:', error)
        throw error;
    }
}

export async function createData(headers, formData, what) {
    try {
        const TOKEN = localStorage.getItem("token") ?? '';
        let AUTHORIZATION = {}
        if (TOKEN) {
            AUTHORIZATION = { 'Authorization': `Bearer ${TOKEN}` }
        }

        const updatedHeaders = {
            ...headers, // Utilisation des headers existants (s'ils sont présents)
            ...AUTHORIZATION, // Utilisation de l'en-tête d'autorisation s'il existe
        };
        const response = await fetch(`${API_BASE_URL}/${what}/`, {
            method: 'POST',
            headers: updatedHeaders,
            body: formData
        });
        return response
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données:', error)
        throw error;
    }
}

export async function updateData(headers, dataForm, what, id) {
    try {
        const TOKEN = localStorage.getItem("token") ?? '';
        let AUTHORIZATION = {}
        if (TOKEN) {
            AUTHORIZATION = { 'Authorization': `Bearer ${TOKEN}` }
        }
        const updatedHeaders = {
            ...headers, // Utilisation des headers existants (s'ils sont présents)
            ...AUTHORIZATION, // Utilisation de l'en-tête d'autorisation s'il existe
        };

        const response = await fetch(`${API_BASE_URL}/${what}/${id}`, {
            method: 'PUT',
            headers: updatedHeaders,
            body: dataForm,
        });

        return response
    } catch (error) {
        throw new Error('Erreur lors de la mise à jour des informations de l\'événement:', error);
    }
}

export async function infoPdf(id) {
    try {
        const response = await fetch(`${API_BASE_URL}/events/pdf/${id}`);
        if (response.ok) {
            return response;
        } else {
            throw new Error('Une erreur s\'est produite lors de la récupération des données');
        }
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données:', error)
        throw error;
    }
}

export async function sendMail(mailOptions) {
    try {
        const response = await fetch(`${API_BASE_URL}/mail/send`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(mailOptions)
        });
        return response
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données:', error)
        throw error;
    }
}