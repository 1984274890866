import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
// Components
import Price from '../Price';
import { AlertContext } from '../AlertContext';
// Api
import { sendMail, updateData } from '../../api/api'

const ValidateButton = ({ event, onValidate, what }) => {
    const [showModal, setShowModal] = useState(false);
    const { handleSubmit, register } = useForm()
    const { openModal } = useContext(AlertContext);

    const handleValidate = async (data) => {
        try {
            const headers = { 'Content-Type': 'application/json' }
            const response = await updateData(headers, JSON.stringify(data), what, event.id)
            if (response.ok) {
                onValidate(event.id);
                openModal('Validé avec succès');
                setShowModal(false);
                const linkE = `${window.location.href.replace(window.location.pathname, '')}/events/vehicule/${event.VehiculeId}?re=${event.id}`
                const convertDate = (date) => {
                    return new Date(date).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric", }).replace(/\//g, '-')
                }
                const mailOptions = {
                    to: event.email,
                    subject: 'Boulevard Auto - Confirmation de réservation',
                    text: `Bonjour ${event.name} ${event.firstname},\n\nVotre demande de location pour un ${event.Vehicule.marque} ${event.Vehicule.modele} du ${convertDate(event.dateLocation)} au ${convertDate(event.dateRestitution)} à destination de ${event.destination} à été confirmé au prix de ${data.price} €.\nVotre réservation reste consultable via ce lien : ${linkE}, Merci.\n\nÀ bientôt chez Boulevard Auto.`,
                    html: `<h1>Bonjour ${event.name} ${event.firstname},</h1><br/><p style="font-size: 20px">Votre demande de location pour un ${event.Vehicule.marque} ${event.Vehicule.modele} du <span style="font-weight: 900">${convertDate(event.dateLocation)}</span> au <span style="font-weight: 900">${convertDate(event.dateRestitution)}</span> à destination de <span style="font-weight: 900">${event.destination}</span> à été confirmé au prix de <span style="font-weight: 900">${data.price} €</span>.<br/>Votre réservation reste consultable via ce <a href="${linkE}" style="font-weight: 900">lien</a>, Merci.</p><br/><p style="font-size: 20px">À bientôt chez Boulevard Auto.</p>`,
                    subjectBA: 'Notification - Réservation acceptée',
                    textBA: `Vous venez d'accepter la demande de réservation de ${event.name} ${event.firstname},\n\nLa location porte sur le véhicule ${event.Vehicule.marque} ${event.Vehicule.modele} et sera loué du ${convertDate(event.dateLocation)} au ${convertDate(event.dateRestitution)} à destination de ${event.destination}.\nConfirmé au prix de ${data.price} €.\n\nÀ bientôt.`,
                    htmlBA: `<h1>Vous venez d'accepter la demande de réservation de ${event.name} ${event.firstname},</h1><br/><p style="font-size: 20px">La location porte sur le véhicule ${event.Vehicule.marque} ${event.Vehicule.modele} et sera loué du <span style="font-weight: 900">${convertDate(event.dateLocation)}</span> au <span style="font-weight: 900">${convertDate(event.dateRestitution)}</span> à destination de <span style="font-weight: 900">${event.destination}</span>.</br> Confirmé au prix de <span style="font-weight: 900">${data.price} €</span>.</p><br/><p style="font-size: 20px">À bientôt</p>`,
                };
                await sendMail(mailOptions)
            } else {
                const responseData = await response.json()
                openModal(responseData.message, 'error');
            }
        } catch (error) {
            // openModal('Erreur lors de la validation', 'error');
            console.error('Erreur lors de la validation :', error);
        }
    };

    const handleCancelModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <button onClick={() => setShowModal(true)} aria-label={`Valider ${what} ${event.id}`} className='pointer'>Valider</button>
            {showModal && (
                <Price
                    onSubmit={handleSubmit(handleValidate)}
                    onCancel={handleCancelModal}
                    register={register}
                    message={`À quel prix voulez-vous valider cette réservation ?`}
                />
            )}
        </>
    );
};

export default ValidateButton;
