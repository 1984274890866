import { Outlet } from "react-router-dom";
// Components
import Nav from "../components/Nav";
// Styles
import '../styles/Admin.css'

const Admin = () => {

    return (
        <section className="admin page flex">
            <Nav what={'admin'} />
            <Outlet />
        </section>
    );
};

export default Admin;