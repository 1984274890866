import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { PDFDownloadLink } from '@react-pdf/renderer'
// Components
import Form from "./Form"
import Download from "./Download"
// Data
import formData from "../data/FormData"
// Api
import { infoPdf } from "../api/api"
// Styles
import '../styles/Contract.css'

const Contract = () => {
    const { handleSubmit, register, setValue, formState: { errors } } = useForm()
    const { id } = useParams()
    const [dataF, setDataF] = useState(null)
    const [download, setDownload] = useState(false)

    useEffect(() => {
        const fetchInfosPdf = async () => {
            try {
                const responseData = await infoPdf(id)
                const responseJson = await responseData.json()
                Object.entries(responseJson).forEach(([key, value]) => {
                    setValue(key, value)
                })
            } catch (error) {
                console.log('pas d\'info')
            }
        }
        fetchInfosPdf()
    }, [])

    const handleFormSubmit = (data) => {
        setDataF(data)
        setDownload(true)
    }

    const getLink = () => {
        const linkPdf = document.querySelector('.link-pdf').href
        // window.open(linkPdf, '_blank')
        const link = document.createElement('a')
        link.href = linkPdf
        link.target = '_blank'
        link.download = `${dataF.nom} - ${dataF.prenom}.pdf`
        link.click()
        handleModal()
    }

    const handleModal = () => {
        setDownload(false)
        setDataF(null)
    }

    return (
        <section className="flex fdc">
            <section className="contrat">
                <Form fields={formData.contract} onSubmit={handleSubmit(handleFormSubmit)} register={register} errors={errors} />
            </section>

            {download && dataF && (
                <section className="modal flex">
                    <section className="modal-content">
                        <h3>Confirmer</h3>
                        <p>Êtes-vous sûr de vouloir télécharger le contrat ?</p>
                        <aside className="modal-actions">
                            <button className="pointer" onClick={handleModal} >Annuler</button>
                            <PDFDownloadLink
                                document={<Download data={dataF} />}
                                fileName={`${dataF.nom} - ${dataF.prenom}`}
                                className='link-pdf'
                                style={{ display: 'none' }}
                            >
                                {({ loading }) =>
                                    loading ? 'Chargement...' : <button className="pointer" >Télécharger</button>
                                }
                            </PDFDownloadLink>
                            <button className="pointer" onClick={getLink}>
                                Télécharger
                            </button>
                        </aside>
                    </section>
                </section>
            )}
        </section>
    )
}

export default Contract
