import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
// Styles
import '../styles/ConfirmationModal.css'

const Price = ({ onCancel, message, onSubmit, register }) => {
    const { handleSubmit } = useForm()
    
    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        };
    }, [])

    return (
        <div className="modal price flex">
            <div className="modal-content">
                <h3>Confirmer</h3>
                <form className='flex fdc' onSubmit={handleSubmit(onSubmit)}>
                    <p>{message}</p>
                    <label className='container-price flex'>
                        <input type="text" name='price' {...register('price')} /><span>€</span>
                    </label>
                    <div className="modal-actions">
                        <button onClick={onCancel} className="modal-cancel pointer">Annuler</button>
                        <button className="modal-confirm pointer">Confirmer</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Price;
