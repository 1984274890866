import React from 'react';

const Cookies = () => {
    return (
        <section>
            Cookies (pas ceux qu'on mange)
        </section>
    );
};

export default Cookies;