// React
import { useRef, useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import CryptoJS from 'crypto-js'
// Components
import { AuthContext } from './AuthProvider'
import { AlertContext } from './AlertContext'
import ConfirmationModal from './ConfirmationModal'
import CreateEvent from './CreateEvent'
// Calendar
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
// Data
import formData from '../data/FormData'
// Api
import { fetchEventsByVehiculesId, updateEvent, getDataById, deleteAction } from '../api/api'
// Styles
import '../styles/MyCalendar.css'

const MyCalendar = ({ getId }) => {
    const id = useParams().id ?? getId
    const { user } = useContext(AuthContext);
    const { openModal } = useContext(AlertContext);
    const calendarRef = useRef(null)
    const [events, setEvents] = useState([])
    const [selectedSlot, setSelectedSlot] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showModalD, setShowModalD] = useState(false);
    const [deleteData, setDeleteData] = useState(false);
    const [responsive, setResponsive] = useState('')
    const [calendar, setCalendar] = useState(null);
    const [dateClick, setDateClick] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const formattedEvents = await fetchEventsByVehiculesId(id);
                setEvents(formattedEvents);
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la récupération des données:', error);
            }
        }
        fetchEvents()
    }, [])

    useEffect(() => {
        document.body.style.overflow = showModal ? 'hidden' : 'auto'

        return () => {
            document.body.style.overflow = 'auto'
        };
    }, [showModal])

    useEffect(() => {
        setResponsive(window.innerWidth > 500)
    }, [window.innerWidth])

    const deleteEvent = () => {
        if (((deleteData.id === CryptoJS.AES.decrypt(localStorage.getItem('event') || '', '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString(CryptoJS.enc.Utf8)) && deleteData.classNames[0] === 'waiting') || (user && user.admin)) {
            setShowModalD(true)
        }
    }

    const infoEvent = (arg) => {
        if (((arg.event.id === CryptoJS.AES.decrypt(localStorage.getItem('event') || '', '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString(CryptoJS.enc.Utf8)) && arg.event.classNames[0] === 'waiting') || (user && user.admin)) {
            const fetchEvent = async () => {
                try {
                    const oneEvent = await getDataById('events', arg.event.id);
                    oneEvent.status = oneEvent.status === 0 ? 'Non validé' : 'Validé'
                    oneEvent.kilomprev += ' km'
                    setShowModal(oneEvent)
                } catch (error) {
                    console.error('Une erreur s\'est produite lors de la récupération des données:', error);
                }
            }
            fetchEvent()
            setDeleteData({ id: arg.event.id, classNames: arg.event.classNames })
        }
    }

    const handleCancelModal = () => {
        setShowModal(false)
    }
    console.log(window.location.protocol, window.location.host, window.location.href, window.location.pathname)
    const handleCancelModalD = () => {
        setShowModalD(false)
    }

    const handleConfirmModal = async () => {
        if ((
            (deleteData.id === CryptoJS.AES.decrypt((localStorage.getItem('event') || ''), '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString(CryptoJS.enc.Utf8)) && deleteData.classNames[0] === 'waiting') || (user && user.admin)) {
            try {
                const success = await deleteAction('events', deleteData.id);
                if (success) {
                    setShowModal(false);
                    setShowModalD(false);
                    setEvents((prevData) => prevData.filter((item) => item.id !== deleteData.id))
                    localStorage.removeItem('event')
                    openModal(`Supprimé avec succès`);
                }
            } catch (error) {
                console.error('Erreur lors de la suppression de la catégorie:', error);
            }
        }
    };

    useEffect(() => {
        const calendarEl = calendarRef.current
        const newCalendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, interactionPlugin],
            initialView: 'dayGridMonth', // Vue mois
            // initialView: 'dayGridYear',
            // initialView: 'dayGridWeek', // Vue semaine
            // dayHeaderFormat: { weekday: 'short' }, // Vue semaine
            locale: frLocale,
            events: events,
            selectOverlap: false,
            eventOverlap: false,
            selectable: responsive,
            editable:
                (user && user.admin) ||
                (CryptoJS.AES.decrypt(localStorage.getItem('event') || '', '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString(CryptoJS.enc.Utf8))
            ,
            eventClick: infoEvent,
            headerToolbar: {
                start: 'title',
                center: 'prev today next',
                end: 'dayGridMonth dayGridYear',
            },
            eventContent: (arg) => {
                return {
                    html: `<p class='event-title'>${(user && user.admin ? arg.event.title : '') || (localStorage.getItem('event') && arg.event.id === CryptoJS.AES.decrypt(localStorage.getItem('event'), '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString(CryptoJS.enc.Utf8) ? arg.event.title : '')}</p>`
                };
            },
            dateClick: function (info) {
                if (!responsive) {
                    const { dateStr } = info;
                    setSelectedSlot(prevDateMobile => [...prevDateMobile, dateStr]);
                    setDateClick(date => {
                        if (date.length < 1) {
                            info.dayEl.style.background = 'var(--third-color)'
                            return info
                        }
                        else {
                            date.dayEl.style.background = 'none'
                            return ''
                        }
                    });
                }
            },
            select: function (info) {
                const { startStr, endStr } = info;

                // if (start.getDay() === 0 || end.getDay() === 1) {
                //     return openModal('Un véhicule ne peut pas être récupéré ou restitué un Dimanche', 'error')
                // }
                // else {
                //     const currentDate = new Date(endStr);
                //     currentDate.setDate(currentDate.getDate() - 1);
                //     const end = currentDate.toISOString().split('T')[0];

                //     setSelectedSlot([startStr, end])
                // }
                const currentDate = new Date(endStr);
                currentDate.setDate(currentDate.getDate() - 1);
                const end = currentDate.toISOString().split('T')[0];

                setSelectedSlot([startStr, end])
            },
            selectConstraint: {
                start: new Date().setHours(0, 0, 0, 0),
            },
            eventDrop: async function (info) {
                const { id, startStr, endStr, start, end } = info.event;

                if (start.getDay() === 0 || (end && end.getDay() === 0)) {
                    return openModal('Un véhicule ne peut pas être récupéré ou restitué un Dimanche', 'error')
                }
                else {
                    const eventData = {
                        id: id,
                        dateLocation: new Date(startStr).toISOString().split('T')[0],
                        dateRestitution: endStr ? new Date(endStr).toISOString().split('T')[0] : new Date(startStr).toISOString().split('T')[0],
                    };

                    if ((user && user.admin) || (id === (CryptoJS.AES.decrypt(localStorage.getItem('event') || '', '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString(CryptoJS.enc.Utf8)))) {
                        try {
                            const response = await updateEvent(eventData);
                            if (response.ok) {
                                openModal('Votre nouvelle demande à bien été prise en compte')
                            }
                        } catch (error) {
                            console.error('Erreur lors de la mise à jour des informations de l\'événement:', error);
                        }
                    }
                }
            },
        })
        newCalendar.render()
        setCalendar(newCalendar);

        return () => {
            newCalendar.destroy()
        }
    }, [events])

    function closeForm() {
        setSelectedSlot([])
    }

    const addEvent = (event) => {
        calendar.addEvent(event);
        closeForm()
    }

    return (
        <section className='container-calendar'>
            <div ref={calendarRef} className={user && user.admin ? 'adminasede' : ''} />
            {(selectedSlot.length > 1) && (
                <section className='flex fdc calendar'>
                    <CreateEvent fields={formData.events} what={'events'} calendar={selectedSlot} createEvent={addEvent} closeForm={closeForm} />
                </section>
            )}
            {showModal && (
                <section className='modal flex'>
                    <section className='modal-content'>
                        <article className='modal-infos-e'>
                            {
                                Object.values(showModal).map((v, i) => (
                                    v && <p key={i}>{v}</p>
                                ))
                            }
                        </article>
                        <aside className='modal-actions'>
                            <button className='pointer' onClick={handleCancelModal}>Fermer</button>
                            <button className='pointer' onClick={deleteEvent}>Supprimer</button>
                        </aside>
                    </section>
                </section>
            )}
            {showModalD && (
                <ConfirmationModal
                    onConfirm={handleConfirmModal}
                    onCancel={handleCancelModalD}
                    message={`Voulez-vous vraiment supprimer ce créneau ?`}
                />
            )}
        </section>
    )
}

export default MyCalendar