import { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// Components
import Form from './Form';
import Image from './Image';
import { AlertContext } from './AlertContext';
// Api
import { updateData, getDataById } from '../api/api'
//  Data
import formData from '../data/FormData'
// Styles
import '../styles/Update.css'

const Update = () => {
    const { handleSubmit, register, formState: { errors }, setValue } = useForm();
    const { what, id } = useParams();
    const { openModal } = useContext(AlertContext)
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUpdate = async () => {
            try {
                const responseData = await getDataById(what, id);
                formData[what].map((d) => {
                    setValue(d.name, responseData[d.name])
                })
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la récupération des données:', error);
            }
        };
        fetchUpdate();
    }, []);

    const handleModify = async (data) => {
        try {
            let dataForm;
            let headers;

            if (hasFiles(data)) {
                dataForm = new FormData();
                Object.entries(data).forEach(([key, value]) => {
                    if (value instanceof FileList && value.length > 0) {
                        for (let i = 0; i < value.length; i++) {
                            dataForm.append(key, value[i]);
                        }
                    } else if (value !== null && value !== undefined) {
                        dataForm.append(key, value);
                    }
                });

                headers = {};
            } else {
                dataForm = JSON.stringify(data);
                headers = {
                    'Content-Type': 'application/json'
                };
            }
            const response = await updateData(headers, dataForm, what, id)
            if (response.ok) {
                navigate(-1)
                openModal('Les modifications ont bien été effectuées')
            }
            else {
                openModal('Les modifications n\'ont pas pu être appliquées', 'error')
            }
        } catch (error) {
            console.error(`Erreur : `, error);
        }
    };

    const hasFiles = (data) => {
        for (const key in data) {
            const value = data[key];
            if (value instanceof FileList && value.length > 0) {
                return true;
            }
        }
        return false;
    };

    return (
        <section className='up flex'>
            <picture>
                <img src="../../img/vito-mercedes.png" alt="Véhicule de présentation Mercedes Vito" className='imgCover' />
            </picture>
            <section className='flex formUp'>
                <h2>Modifier {what}</h2>
                <Form fields={formData[what]} onSubmit={handleSubmit(handleModify)} register={register} errors={errors} />
            </section>
        </section>
    );
};

export default Update;