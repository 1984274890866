// Components
import Create from "./Create";
// Data
import formData from "../data/FormData"
// Styles
import '../styles/Sign.css'

const Sign = () => {
    return (
        <section className="sign">
            <section id="signup" className="flex">
                <section className="sign-img">
                    <a href="#login" className="flex">Connexion</a>
                    <img src="img/vito-mercedes.png" alt="Véhicule de présentation Mercedes Vito" className="imgCover" />
                </section>
                <Create fields={formData.signup} what={'auth/signup'} message="Bienvenue !" title='Inscrivez-vous !' />
            </section>
            <section id="login" className="flex">
                <Create fields={formData.login} what={'auth/login'} message="Bonjour Boulevard Auto !" title='Connectez-vous !' />
                <section className="sign-img">
                    <a href="#signup" className="flex">Inscription</a>
                    <img src="img/vito-mercedes.png" alt="Véhicule de présentation Mercedes Vito" className="imgCover" />
                </section>
            </section>
        </section>
    );
};

export default Sign;