import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Components
import ByDate from "../components/Filter/ByDate";
import Image from "./Image";
// Api
import { getData } from "../api/api";
// Styles
import '../styles/Filter.css'

const Filter = ({ children }) => {
    const [byFilter, setByFilter] = useState([]);
    const [child, setChild] = useState(true);
    const [nb, setNb] = useState(0);

    useEffect(() => {
        const fetchNb = async () => {
            try {
                const responseData = await getData('vehicules')
                setNb(responseData.length);
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la récupération des données:', error);
            }
        };
        fetchNb();
    }, []);

    const byFilterHandle = (data) => {
        setByFilter(data)
        setChild(false)
    }

    const resetFilter = () => {
        setByFilter([])
        setChild(true)
        localStorage.removeItem('filter')
    }

    return (
        <>
            <section className="contain-filter flex">
                <article className="flex fdc">
                    <h1>Réservez votre véhicule</h1>
                    <ByDate onFilterHandle={byFilterHandle} reset={resetFilter} />
                </article>
                <figure className="flex">
                    <img src="img/vito-wbg.png" alt="Véhicule de présentation" className="imgCover expo" />
                    {nb > 0 &&
                        <aside className="bubbleHome">
                            <span>
                                <img src="img/icon/add.png" alt="Plus" />
                                de {nb}
                            </span>
                            <p className="flex">véhicules
                                <a href="#categories" className="flex">
                                    <img src="img/icon/right-arrow.png" alt="Découvrez les" />
                                </a>
                            </p>
                        </aside>
                    }
                </figure>
            </section>
            {byFilter.length > 0 ? (
                <section className="vehicules list filterveh flex fdc">
                    {byFilter.map((f) => (
                        <NavLink to={`events/vehicule/${f.id}`} className='flex linkVeh' key={f.id}>
                            <article className="flex vehicule">
                                <Image imageUrl={f.avatar} />
                                <section className='flex'>
                                    <p>{f.marque}</p>
                                    -
                                    <p>{f.modele}</p>
                                </section>
                            </article>
                        </NavLink>
                    ))}
                </section>
            ) : (
                <section className="flex">
                    {!child &&
                        <p>
                            Aucun véhicule n'est disponible à ces dates
                        </p>
                    }
                </section>
            )}
            {child && children}
        </>
    );
};

export default Filter;