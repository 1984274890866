import { useState } from "react"

export default function PasswordField({ name, type, label, register, validation, errors, children }) {

    const [ntype, setNType] = useState({ type, txt: 'Voir mdp', src: 'view.png' })

    function handleType() {
        ntype.type !== 'text' ? setNType({ type: 'text', txt: 'Masquer mdp', src: 'hide.png' }) : setNType({ type: 'password', txt: 'Voir mdp', src: 'view.png' })
    }
    
    return (
        <>
            <label htmlFor={name}>
                {label}
            </label>
            <div className="flex password">
                <input type={ntype.type} id={name} {...register(name, validation)} {...children} />
                <button type="button" aria-label={ntype.txt} onClick={handleType} className="password-visibility pointer">
                    <img src={`img/icon/${ntype.src}`} alt={ntype.txt} className="imgCover" />
                </button>
            </div>
            <p role="alert">{errors[name] && errors[name].message}</p>
        </>
    )
}