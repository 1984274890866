// Components
import Filter from "../components/Filter";
import Categories from "../components/Categories";

const Home = () => {
    return (
        <section className="home">
            <Filter>
                <Categories />
            </Filter>
        </section>
    );
};

export default Home;
