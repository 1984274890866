import { useForm } from 'react-hook-form'
// Components
import Field from './Form/Field'
import File from './Form/File'
import Select from './Form/Select'
import PasswordField from './Form/PasswordField'
import Checkbox from './Form/Checkbox'
import Radio from './Form/Radio'
import Textarea from './Form/Textarea'
import Title from './Form/Title'
import Submit from './Form/Submit'
// Styles
import '../styles/Form.css'

const fieldTypes = {
    text: Field,
    number: Field,
    email: Field,
    tel: Field,
    date: Field,
    file: File,
    select: Select,
    password: PasswordField,
    checkbox: Checkbox,
    radio: Radio,
    textarea: Textarea,
    titre: Title,
    submit: Submit,
}

function Form({ fields, onSubmit, register, errors }) {
    const { handleSubmit } = useForm()

    return (
        <>
            <form className='flex' onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                {fields.map(({ name, type, label, taille, validation, more }, index) => {
                    const FieldComponent = fieldTypes[type]
                    return (
                        <section key={index} className="flex fdc" style={{ width: taille }}>
                            <FieldComponent type={type} name={name} label={label} register={register} validation={validation} errors={errors}>
                                {more}
                            </FieldComponent>
                        </section>
                    )
                })}
            </form>
        </>
    )
}

export default Form