import { useEffect, useState } from "react"
// Api
import { getData } from '../../api/api'

export default function Select({ name, label, children, register, validation, errors }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await getData(children);
                setData(responseData);
            } catch (error) {
                console.log('Une erreur s\'est produite lors de la récupération des données');
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <label htmlFor={name}>
                {label}
            </label>
            <select {...register(name, validation)}>
                <option value="">Veuillez choisir: {children}</option>
                {data &&
                    data.map((d) =>
                        <option key={d.id} value={d.id}>{d.name}</option>
                    )}
            </select>
            <p role="alert">{errors[name] && errors[name].message}</p>
        </>
    )
}