// Styles
import '../../styles/Radio.css'

const Radio = ({ name, label, children, register, validation, errors }) => {

    return (
        <>
            <fieldset className='radio flex'>
                <legend>{label}</legend>
                <section className='contain-radio flex'>
                    {children &&
                        children.list.map((r, i) =>
                            <label className='pointer flex' htmlFor={r.name} key={i}>
                                <input type="radio" id={r.name} name={name} value={r.value} {...register(name, validation)} />
                                <span></span>
                                {r.label}
                            </label>
                        )}
                </section>
            </fieldset>
            <p role="alert">{errors[name] && errors[name].message}</p>
        </>
    )
}

export default Radio