import { useContext, useState } from 'react';
// Components
import { AuthContext } from '../AuthProvider';
import ConfirmationModal from '../ConfirmationModal';
// Styles
import '../../styles/Disconnect.css'

const Disconnect = () => {
    const { logout } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);

    const handleLogout = () => {
        logout();
    };

    const handleConfirmModal = () => {
        handleLogout();
        setShowModal(false);
    };

    const handleCancelModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {localStorage.getItem('token') &&
                <>
                    <button className='pointer disconnect' onClick={() => setShowModal(true)}>Déconnexion</button>
                    {showModal && (
                        <ConfirmationModal
                            onConfirm={handleConfirmModal}
                            onCancel={handleCancelModal}
                            message={`Voulez-vous vraiment vous déconnecter ?`}
                        />
                    )}
                </>
            }
        </>
    );
};

export default Disconnect;
