export default function Textarea({ name, label, register, validation, errors }) {
    return (
        <>
            <label htmlFor={name}>
                {label}
            </label>
            <textarea id={name} {...register(name, validation)} ></textarea>
            <p role="alert">{errors[name] && errors[name].message}</p>
        </>
    )
}