import { Link } from 'react-router-dom';

const UpdateButton = ({ what, id}) => {
    return (
        <Link to={`/update/${what}/${id}`}>
            <button className='pointer'>Modifier</button>
        </Link>
    );
};

export default UpdateButton;