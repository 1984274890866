import { useEffect, useState } from 'react';
// Components
import AgendaFlux from './AgendaFlux';
import AgendaNav from './AgendaNav';
// Api
import { agenda } from '../api/api'
// Styles
import '../styles/Agenda.css'

const Agenda = () => {
    const [eventsByD, setEventsByD] = useState({ livraisons: [], restitutions: [] })
    const [date, setDate] = useState(new Date().toISOString().split('T')[0])

    useEffect(() => {
        const fetchEventsByD = async () => {
            try {
                const responseData = await agenda(date);
                setEventsByD(responseData)
            } catch (error) {

            }
        }
        fetchEventsByD()
    }, [date])

    const handleFormSubmit = (e) => {
        setDate(e.target.value)
    };

    const handleDate = async (sign) => {
        const currentDate = new Date(date);
        currentDate.setDate(currentDate.getDate() + sign);
        const newDate = currentDate.toISOString().split('T')[0]
        setDate(newDate)
    }

    return (
        <section className='flex fdc'>
            <label className='flex fdc' htmlFor='agenda'>
                Sélectionnez une date d'agenda
                <section className='flex container-date'>
                    <AgendaNav side={'prev'} handleDate={handleDate} />
                    <input type="date" className='agenda-date pointer flex' value={date} onChange={handleFormSubmit} id='agenda' />
                    <AgendaNav side={'next'} handleDate={handleDate} />
                </section>
            </label>
            <section className='agenda'>
                <AgendaFlux what={'départs'} data={eventsByD.livraisons} />
                <AgendaFlux what={'retours'} data={eventsByD.restitutions} />
                {eventsByD.restitutions.length < 1 && eventsByD.livraisons.length < 1 && <p className='no-agenda'>Aucun enregistrement</p>}
            </section>
        </section>
    );
};

export default Agenda;