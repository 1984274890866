import { useEffect, useState, useContext } from "react"
// Components
import { AuthContext } from './AuthProvider'
import ValidateButton from "./Action/ValidateButton"
import DeleteButton from "./Action/DeleteButton"
import ValidateFlux from "./ValidateFlux"
// Api
import { getData, sendMail } from '../api/api'
// Styles
import '../styles/Validate.css'

const Validate = () => {
    const [validate, setValidate] = useState([])
    const { user } = useContext(AuthContext)

    useEffect(() => {
        const fetchValidate = async () => {
            try {
                const responseData = await getData('events/state/tovalidate')
                setValidate(responseData)
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la récupération des données:', error)
            }
        }
        fetchValidate()
    }, [])

    const handleValidate = (id) => {
        setValidate((prevData) => {
            const updatedValidate = { ...prevData }
            Object.keys(updatedValidate).forEach((key) => {
                updatedValidate[key].events = updatedValidate[key].events.filter((event) => event.id !== id)
            })
            return updatedValidate
        })
        console.log('validate')
    }

    const handleDelete = async (id, event) => {
        handleValidate(id)
        const convertDate = (date) => {
            return new Date(date).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric", }).replace(/\//g, '-')
        }
        const mailOptions = {
            to: event.email,
            subject: 'Boulevard Auto - Refus de réservation',
            text: `Bonjour ${event.name} ${event.firstname},\n\nVotre demande de location pour un ${event.Vehicule.marque} ${event.Vehicule.modele} du ${convertDate(event.dateLocation)} au ${convertDate(event.dateRestitution)} à destination de ${event.destination} à malheureusement été refusée.\nPour toute demande d'information, contactez-nous, Merci.\n\nÀ bientôt chez Boulevard Auto.`,
            html: `<h1>Bonjour ${event.name} ${event.firstname},</h1><br/><p style="font-size: 20px">Votre demande de location pour un ${event.Vehicule.marque} ${event.Vehicule.modele} du <span style="font-weight: 900">${convertDate(event.dateLocation)}</span> au <span style="font-weight: 900">${convertDate(event.dateRestitution)}</span> à destination de <span style="font-weight: 900">${event.destination}</span> à malheureusement été refusée.<br/>Pour toute demande d'information, veuillez nous contacter, Merci.</p><br/><p style="font-size: 20px">À bientôt chez Boulevard Auto.</p>`,
            subjectBA: 'Notification - Réservation refusée',
            textBA: `Vous venez de refuser la demande de réservation de ${event.name} ${event.firstname},\n\nLa demande concernait la location pour un ${event.Vehicule.marque} ${event.Vehicule.modele} du ${convertDate(event.dateLocation)} au ${convertDate(event.dateRestitution)} à destination de ${event.destination}.\n\nÀ bientôt.`,
            htmlBA: `<h1>Vous venez de refuser la demande de réservation de ${event.name} ${event.firstname},</h1><br/><p style="font-size: 20px">La demande concernait la location pour un ${event.Vehicule.marque} ${event.Vehicule.modele} du <span style="font-weight: 900">${convertDate(event.dateLocation)}</span> au <span style="font-weight: 900">${convertDate(event.dateRestitution)}</span> à destination de <span style="font-weight: 900">${event.destination}</span>.</p><br/><p style="font-size: 20px">À bientôt.</p>`,
        };
        await sendMail(mailOptions)
    }

    return (
        <section className="container-validate flex fdc">
            <h2>Créneaux à valider</h2>
            <section className="flex fdc">
                {Object.keys(validate).map((key) => (
                    <div key={key} className="flex">
                        {validate[key].events &&
                            <>
                                <label className="flex pointer" htmlFor={key}>
                                    {validate[key].vehicule.marque} - {validate[key].vehicule.modele}
                                    <span className="nb_valid">{validate[key].events.length}</span>
                                </label>
                                <input type="checkbox" id={key} />
                            </>
                        }
                        {validate[key].events.map((event) => (
                            <article key={event.id} className="validate flex">
                                <ValidateFlux event={event} />
                                {user && user.admin ?
                                    <aside>
                                        <ValidateButton event={event} onValidate={handleValidate} what={"events/state"} />
                                        <DeleteButton id={event.id} event={event} onDelete={handleDelete} what={'events'} message="Refusé avec succès" >Refuser</DeleteButton>
                                    </aside>
                                    : ''}
                            </article>
                        ))}
                    </div>
                ))}
                {Object.keys(validate).length === 0 && <p>Aucun créneau à valider</p>}
            </section>
        </section>
    )
}

export default Validate