import { NavLink } from "react-router-dom";
// Data
import menuData from '../data/MenuData'
// Styles
import '../styles/Nav.css'

const Nav = ({ what }) => {

    return (
        <nav className='nav'>
            <ul className={`ul${what}`}>
                {menuData[what].map((c, i) =>
                    <li key={i} className='pointer'>
                        {c.icon &&
                            <img src={c.icon} />
                        }
                        <NavLink to={`${c.url}`} activeclassname='active' >{c.nav}</NavLink>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Nav;