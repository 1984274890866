//      .Champs disponibles dans le formulaire

// text: Field,
// email: Field,
// file: Field,
// select: Select,
// password: PasswordField,
// checkbox: Checkbox,
// textarea: Textarea,

//      .Le gap du form est de 2%

const formData = {
    vehicules: [
        {
            name: 'marque',
            type: 'text',
            label: 'Marque',
            taille: '33%',
            validation: {
                required: 'marque requis',
            },
        },
        {
            name: 'modele',
            type: 'text',
            label: 'Modèle',
            taille: '33%',
            validation: {
                required: 'modele requis',
            },
        },
        {
            name: 'immat',
            type: 'text',
            label: 'Immatriculation',
            taille: '30%',
            validation: {
                required: 'requis',
            },
        },
        {
            name: 'carburant',
            type: 'text',
            label: 'Carburant',
            taille: '49%',
            validation: {
                required: 'requis',
            },
        },
        {
            name: 'kilom',
            type: 'text',
            label: 'Nombres de kilomètres',
            taille: '49%',
            more: { className: 'kilom' },
            validation: {
                required: 'requis',
            },
        },
        {
            name: 'couleur',
            type: 'text',
            label: 'Couleur',
            taille: '49%',
            validation: {
                required: 'requis',
            },
        },
        {
            name: 'CategoryId',
            type: 'select',
            label: 'Catégorie',
            taille: '49%',
            more: 'categories',
            validation: {
                required: 'requis',
            },
        },
        {
            name: 'etat',
            type: 'textarea',
            label: 'État',
            validation: {
                required: 'requis',
            },
        },
        {
            name: 'avatar',
            type: 'file',
            label: 'Avatar si vous souhaitez le modifier',
            // validation: {
            //     required: 'requis',
            // },
        },
        // {
        //     name: 'photos',
        //     type: 'file',
        //     label: 'Photos véhicule',
        //     more: {
        //         multiple: true,
        //         // maxFiles: (files) => files && files.length <= 10 || 'Le nombre maximal de fichiers est atteint (10 fichiers maximum)',
        //     },
        // },
        {
            type: 'submit',
            label: 'Ajouter',
        },
    ],

    categories: [
        {
            name: 'name',
            type: 'text',
            label: 'Nom',
            validation: {
                required: 'Nom requis',
            },
        },
        {
            name: 'imageUrl',
            type: 'file',
            label: 'Image',
            // validation: {
            //     required: 'Image requise',
            // },
        },
        {
            type: 'submit',
            label: 'Ajouter',
        },
    ],
    signup: [
        {
            name: 'email',
            type: 'email',
            label: 'E-mail',
            validation: {
                required: 'E-mail requis',
            },
        },
        {
            name: 'name',
            type: 'text',
            label: 'Nom',
            taille: '49%',
            validation: {
                required: 'Nom requis',
            },
        },
        {
            name: 'firstname',
            type: 'text',
            label: 'Prénom',
            taille: '49%',
            validation: {
                required: 'Prénom requis',
            },
        },
        {
            name: 'username',
            type: 'text',
            label: 'Nom d\'utilisateur',
            validation: {
                required: 'Nom d\'utilisateur requis',
            },
        },
        {
            name: 'password',
            type: 'password',
            label: 'Mot de passe',
            // more: {
            //     pattern: {
            //         value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
            //         message: 'Le mot de passe doit contenir au moins 8 caractères, dont au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial',
            //     },
            // },
            validation: {
                required: 'Mot de passe requis',
            },
        },
        {
            type: 'submit',
            label: 'Inscription',
        },
    ],
    login: [
        {
            name: 'email',
            type: 'email',
            label: 'E-mail',
            validation: {
                required: 'E-mail requis',
            },
        },
        {
            name: 'password',
            type: 'password',
            label: 'Mot de passe',
            validation: {
                required: 'Mot de passe requis',
            },
        },
        {
            type: 'submit',
            label: 'Connexion',
        },
    ],
    events: [
        {
            name: 'id',
            type: 'text',
            // label: 'Numéro id véhicule',
            more: {
                className: 'none',
            },
        },
        {
            name: 'VehiculeId',
            type: 'number',
            // label: 'Numéro id véhicule',
            more: {
                className: 'none',
            },
            validation: {
                required: 'E-mail requis',
            },
        },
        {
            name: 'firstname',
            type: 'text',
            label: 'Prénom',
            taille: '49%',
            more: { placeholder: 'Prénom' },
            validation: {
                required: 'Nom requis',
            },
        },
        {
            name: 'name',
            type: 'text',
            label: 'Nom',
            taille: '49%',
            more: { placeholder: 'Nom' },
            validation: {
                required: 'Nom requis',
            },
        },
        {
            name: 'email',
            type: 'email',
            label: 'E-mail',
            taille: '49%',
            more: { placeholder: 'jereserve@gmail.com' },
            validation: {
                required: 'E-mail requis',
            },
        },
        {
            name: 'phone',
            type: 'tel',
            label: 'Téléphone',
            taille: '49%',
            more: { pattern: '[0-9]{2} ?[0-9]{2} ?[0-9]{2} ?[0-9]{2} ?[0-9]{2}', placeholder: '01 02 03 04 05' },
            validation: {
                required: 'Numéro de téléphone requis',
            },
        },
        {
            name: 'kilomprev',
            type: 'text',
            label: 'Kilomètres prévue',
            taille: '49%',
            more: { className: 'kilom' },
            validation: {
                required: 'Nombre de kilomètre requis',
            },
        },
        {
            name: 'destination',
            type: 'text',
            label: 'Destination prévue',
            taille: '49%',
            validation: {
                required: 'Destination requise',
            },
        },
        {
            name: 'dateLocation',
            type: 'date',
            label: 'Départ',
            more: {
                min: new Date().toISOString().split("T")[0],
            },
            taille: '49%',
            validation: {
                required: 'Date début requise',
            },
        },
        {
            name: 'dateRestitution',
            type: 'date',
            label: 'Retour',
            more: {
                min: new Date().toISOString().split("T")[0],
            },
            taille: '49%',
            validation: {
                required: 'Date de restitution requise',
            },
        },
        {
            type: 'submit',
            label: 'Faire une demande de réservation',
        },
    ],
    contract: [
        // RENSEIGNEMENTS RELATIFS AU CONDUCTEUR
        {
            name: 'h2',
            type: 'titre',
            label: 'Renseignements relatifs au(x) conducteur(s)',
        },
        {
            name: 'nom',
            type: 'text',
            label: 'Nom :',
            taille: '23.5%',
        },
        {
            name: 'prenom',
            type: 'text',
            label: 'Prénom :',
            taille: '23.5%',
        },
        {
            name: 'naissance',
            type: 'date',
            label: 'Né(e) le :',
            taille: '23.5%',
        },
        {
            name: 'lieu_naissance',
            type: 'text',
            label: 'à',
            taille: '23.5%',
        },
        {
            name: 'adresse',
            type: 'text',
            label: 'Adresse :',
            taille: '78%',
        },
        {
            name: 'tel',
            type: 'text',
            label: 'Téléphone :',
            taille: '20%',
        },
        {
            name: 'num_permis',
            type: 'text',
            label: 'Permis de conduire n° :',
            taille: '40%',
        },
        {
            name: 'delivrance',
            type: 'text',
            label: 'délivré le :',
            taille: '16%',
        },
        {
            name: 'prefecture',
            type: 'text',
            label: 'à la Préfecture de',
            taille: '40%',
        },
        // AUTRE CONDUCTEUR
        {
            name: 'h2',
            type: 'titre',
            label: 'Autre conducteur',
        },
        {
            name: 'nom_d',
            type: 'text',
            label: 'Nom :',
            taille: '23.5%',
        },
        {
            name: 'prenom_d',
            type: 'text',
            label: 'Prénom :',
            taille: '23.5%',
        },
        {
            name: 'naissance_d',
            type: 'date',
            label: 'Né(e) le :',
            taille: '23.5%',
        },
        {
            name: 'lieu_naissance_d',
            type: 'text',
            label: 'à',
            taille: '23.5%',
        },
        {
            name: 'num_permis_d',
            type: 'text',
            label: 'Permis de conduire n° :',
            taille: '40%',
        },
        {
            name: 'delivrance_d',
            type: 'text',
            label: 'délivré le :',
            taille: '16%',
        },
        {
            name: 'prefecture_d',
            type: 'text',
            label: 'à la Préfecture de',
            taille: '40%',
        },
        // VÉHICULE EN LOCATION
        {
            name: 'h2',
            type: 'titre',
            label: 'Véhicule en location',
        },
        {
            name: 'marque',
            type: 'text',
            label: 'Marque',
            taille: '23.5%',
        },
        {
            name: 'modele',
            type: 'text',
            label: 'Modèle',
            taille: '23.5%',
        },
        {
            name: 'immat',
            type: 'text',
            label: 'n° d\'immatriculation',
            taille: '23.5%',
        },
        {
            name: 'carburant',
            type: 'text',
            label: 'Carburant',
            taille: '23.5%',
        },
        // DÉPART
        {
            name: 'h2',
            type: 'titre',
            label: 'Départ',
        },
        {
            name: 'date_depart',
            type: 'text',
            label: 'Date du départ :',
            taille: '49%',
        },
        {
            name: 'date_retour_prevue',
            type: 'text',
            label: 'Date de retour prévue :',
            taille: '49%',
        },
        {
            name: 'heure_depart',
            type: 'text',
            label: 'Heure :',
            taille: '49%',
        },
        {
            name: 'kilom_compteur',
            type: 'text',
            label: 'Km au compteur :',
            taille: '49%',
        },
        {
            name: 'niv_carburant',
            type: 'radio',
            label: 'Niveau de carburant :',
            more: {
                list: [
                    {
                        name: 'zero',
                        label: '0',
                        value: '0',
                    },
                    {
                        name: 'quart',
                        label: '1/4',
                        value: '1/4',
                    },
                    {
                        name: 'demi',
                        label: '1/2',
                        value: '1/2',
                    },
                    {
                        name: 'trois_quart',
                        label: '3/4',
                        value: '3/4',
                    },
                    {
                        name: 'plein',
                        label: '1',
                        value: '1',
                    },
                ],
            },
        },
        {
            name: 'h5',
            type: 'titre',
            label: 'Le carburant reste à la charge du conducteur',
        },
        {
            name: 'h3',
            type: 'titre',
            label: 'État du véhicule au départ',
        },
        {
            name: 'carte_grise',
            type: 'radio',
            label: 'Photocopie de la Carte Grise',
            taille: '49%',
            more: {
                list: [
                    {
                        name: 'cg_oui',
                        label: 'oui',
                        value: 'oui',
                    },
                    {
                        name: 'cg_non',
                        label: 'non',
                        value: 'non',
                    },
                ],
            },
        },
        {
            name: 'attest_assurance',
            type: 'radio',
            label: 'Attestation d\'assurance',
            taille: '49%',
            more: {
                list: [
                    {
                        name: 'aa_oui',
                        label: 'oui',
                        value: 'oui',
                    },
                    {
                        name: 'aa_non',
                        label: 'non',
                        value: 'non',
                    },
                ],
            },
        },
        {
            name: 'kit_secu',
            type: 'radio',
            label: 'Kit de sécurité',
            taille: '49%',
            more: {
                list: [
                    {
                        name: 'ks_oui',
                        label: 'oui',
                        value: 'oui',
                    },
                    {
                        name: 'ks_non',
                        label: 'non',
                        value: 'non',
                    },
                ],
            },
        },
        {
            name: 'dis_stat_eur',
            type: 'radio',
            label: 'Disque de stationnement européen',
            taille: '49%',
            more: {
                list: [
                    {
                        name: 'dse_oui',
                        label: 'oui',
                        value: 'oui',
                    },
                    {
                        name: 'dse_non',
                        label: 'non',
                        value: 'non',
                    },
                ],
            },
        },
        {
            name: 'observations',
            type: 'text',
            label: 'Observations :',
            more: {
                maxLength: '120'
            }
        },
        {
            name: 'fait',
            type: 'text',
            label: "Fait (en double exemplaire) à",
            taille: '49%',
        },
        {
            name: 'date_fait',
            type: 'text',
            label: "le",
            taille: '49%',
        },
        // RETOUR
        // {
        //     name: 'h2',
        //     type: 'titre',
        //     label: 'Retour',
        // },
        // {
        //     name: 'date_retour',
        //     type: 'text',
        //     label: 'Date du retour :',
        //     taille: '49%',
        // },
        // {
        //     name: 'heure_retour',
        //     type: 'text',
        //     label: 'Heure :',
        //     taille: '49%',
        // },
        // {
        //     name: 'kilom_compteur_retour',
        //     type: 'text',
        //     label: 'Km au compteur :',
        // },
        // {
        //     name: 'niv_carburant_r',
        //     type: 'radio',
        //     label: 'Niveau de carburant :',
        //     more: {
        //         list: [
        //             {
        //                 name: 'zero_r',
        //                 label: '0',
        //                 value: '0',
        //             },
        //             {
        //                 name: 'quart_r',
        //                 label: '1/4',
        //                 value: '1/4',
        //             },
        //             {
        //                 name: 'demi_r',
        //                 label: '1/2',
        //                 value: '1/2',
        //             },
        //             {
        //                 name: 'trois_quart_r',
        //                 label: '3/4',
        //                 value: '3/4',
        //             },
        //             {
        //                 name: 'plein_r',
        //                 label: '1',
        //                 value: '1',
        //             },
        //         ],
        //     },
        // },
        // {
        //     name: 'h5',
        //     type: 'titre',
        //     label: 'Le carburant reste à la charge du conducteur',
        // },
        // {
        //     name: 'h3',
        //     type: 'titre',
        //     label: 'État du véhicule au retour',
        // },
        // {
        //     name: 'carte_grise_r',
        //     type: 'radio',
        //     label: 'Photocopie de la Carte Grise',
        //     taille: '49%',
        //     more: {
        //         list: [
        //             {
        //                 name: 'cg_oui_r',
        //                 label: 'oui',
        //                 value: 'oui',
        //             },
        //             {
        //                 name: 'cg_non_r',
        //                 label: 'non',
        //                 value: 'non',
        //             },
        //         ],
        //     },
        // },
        // {
        //     name: 'attest_assurance_r',
        //     type: 'radio',
        //     label: 'Attestation d\'assurance',
        //     taille: '49%',
        //     more: {
        //         list: [
        //             {
        //                 name: 'aa_oui_r',
        //                 label: 'oui',
        //                 value: 'oui',
        //             },
        //             {
        //                 name: 'aa_non_r',
        //                 label: 'non',
        //                 value: 'non',
        //             },
        //         ],
        //     },
        // },
        // {
        //     name: 'kit_secu_r',
        //     type: 'radio',
        //     label: 'Kit de sécurité',
        //     taille: '49%',
        //     more: {
        //         list: [
        //             {
        //                 name: 'ks_oui_r',
        //                 label: 'oui',
        //                 value: 'oui',
        //             },
        //             {
        //                 name: 'ks_non_r',
        //                 label: 'non',
        //                 value: 'non',
        //             },
        //         ],
        //     },
        // },
        // {
        //     name: 'dis_stat_eur_r',
        //     type: 'radio',
        //     label: 'Disque de stationnement européen',
        //     taille: '49%',
        //     more: {
        //         list: [
        //             {
        //                 name: 'dse_oui_r',
        //                 label: 'oui',
        //                 value: 'oui',
        //             },
        //             {
        //                 name: 'dse_non_r',
        //                 label: 'non',
        //                 value: 'non',
        //             },
        //         ],
        //     },
        // },
        // {
        //     name: 'observations_r',
        //     type: 'text',
        //     label: 'Observations :',
        //     more: {
        //         maxLength: '120',
        //     }
        // },
        // {
        //     name: 'fait_r',
        //     type: 'text',
        //     label: "Fait (en double exemplaire) à",
        //     taille: '49%',
        // },
        // {
        //     name: 'date_fait_r',
        //     type: 'text',
        //     label: "le",
        //     taille: '49%',
        // },
        {
            type: 'submit',
            label: 'Générer',
        },
    ],
    byDate: [
        {
            name: 'h2',
            type: 'titre',
            label: 'Choisissez le par date ...',
        },
        {
            name: 'date_debut',
            type: 'date',
            label: 'Date de début',
            more: {
                min: new Date().toISOString().split("T")[0],
            },
            validation: {
                required: 'Date de début requis',
            },
        },
        {
            name: 'date_fin',
            type: 'date',
            label: 'Date de fin',
            more: {
                min: new Date().toISOString().split("T")[0],
            },
            validation: {
                required: 'Date de fin requise',
            },
        },
        {
            type: 'submit',
            label: 'Filtrer',
        },
    ],
}

export default formData