import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
// Components
import { AlertContext } from './AlertContext';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate()
    const { openModal } = useContext(AlertContext)

    const login = (token) => {
        const decodedToken = token && jwt_decode(token);

        if (decodedToken) {
            setUser({
                id: decodedToken.userId,
                admin: decodedToken.isAdmin,
            });
        }
    };

    // Fonction pour se déconnecter
    const logout = useCallback((msg = 'Vous avez été déconnecté') => {
        setUser(null);
        localStorage.removeItem('token')
        navigate('/')
        openModal(msg)
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            const expirationDate = new Date(decodedToken.exp * 1000);
            const currentDate = new Date();

            if (currentDate > expirationDate) {
                logout('Votre session a expiré. Veuillez vous reconnecter.');
            } else {
                login(token);
            }
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };