import { NavLink } from "react-router-dom";

const AgendaFlux = ({ what, data }) => {
    return (
        <>
            {data.length > 0 &&
                <section className={`${what} flex`}>
                    <h3>{what}</h3>
                    {data.map((d) =>
                        <article key={d.id} className="flex">
                            <span className="user">{d.name} {d.firstname}</span>
                            :
                            <span>{d.Vehicule.marque}</span>
                            <span>{d.Vehicule.modele}</span>
                            <button className="flex">
                                <NavLink to={`/contrat/${d.id}`}>contrat</NavLink>
                            </button>
                        </article>
                    )}
                </section>
            }
        </>
    );
};

export default AgendaFlux;