import { useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
// Components
import { AlertContext } from './AlertContext';
import Form from './Form';
// Api
import { createData, sendMail } from '../api/api'

const CreateEvent = ({ fields, what, calendar = '', createEvent, closeForm }) => {
    const { handleSubmit, register, formState: { errors }, setValue } = useForm();
    const { id } = useParams()
    const { openModal } = useContext(AlertContext)
    const generate = uuidv4()

    useEffect(() => {
        if (calendar) {
            setValue('id', generate);
            setValue('VehiculeId', id);
            setValue('dateLocation', calendar[0]);
            setValue('dateRestitution', calendar[1])
        }
    }, [generate]);

    const handleFormSubmit = async (data) => {
        try {
            const response = await createData({ 'Content-Type': 'application/json' }, JSON.stringify(data), what);
            const responseData = await response.json();
            if (response.ok) {
                createEvent({
                    id: data.id,
                    title: data.name,
                    start: `${calendar[0]}T00:00:00.000Z`,
                    end: `${calendar[1]}T00:00:00.000Z`,
                    classNames: ['waiting'],
                })
                openModal('Votre demande de réservation à bien été prise en compte');
                localStorage.setItem('event', CryptoJS.AES.encrypt(generate.toString(), '15800ae02f5cd1c1b0a17a95bb88ed7de1e61600b710f1c61f686df2b96d3709').toString())
                const linkE = `${window.location.protocol}//${window.location.host}${window.location.pathname}?re=${data.id}`
                const convertDate = (date) => {
                    return new Date(date).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric", }).replace(/\//g, '-')
                }
                const mailOptions = {
                    to: data.email,
                    subject: 'Boulevard Auto - Demande de réservation',
                    text: `Bonjour ${data.name} ${data.firstname},\n\nVotre demande de location pour un véhicule du ${convertDate(data.dateLocation)} au ${convertDate(data.dateRestitution)} à destination de ${data.destination} à bien été prise en compte et une confirmation vous sera faite sous peu.\nVotre demande de réservation reste consultable via ce lien : ${linkE}, Merci.\n\nÀ bientôt chez Boulevard Auto.`,
                    html: `<h1>Bonjour ${data.name} ${data.firstname},</h1><br/><p style="font-size: 20px">Votre demande de location pour un véhicule du <span style="font-weight: 900">${convertDate(data.dateLocation)}</span> au <span style="font-weight: 900">${convertDate(data.dateRestitution)}</span> à destination de <span style="font-weight: 900">${data.destination}</span> à bien été prise en compte et une confirmation vous sera faite sous peu.<br/>Votre demande de réservation reste consultable via ce <a href="${linkE}" style="font-weight: 900">lien</a>, Merci.</p><br/><p style="font-size: 20px">À bientôt chez Boulevard Auto.</p>`,
                    subjectBA: 'Notification - Demande de réservation',
                    textBA: `Une demande de réservation effectué par ${data.name} ${data.firstname},\n\nLa demande de location pour un véhicule du ${convertDate(data.dateLocation)} au ${convertDate(data.dateRestitution)} à destination de ${data.destination} à été effectué et est en attente de réponse.\n\nÀ bientôt.`,
                    htmlBA: `<h1>Une demande de réservation effectuée par ${data.name} ${data.firstname},</h1><br/><p style="font-size: 20px">La demande de location pour un véhicule du <span style="font-weight: 900">${convertDate(data.dateLocation)}</span> au <span style="font-weight: 900">${convertDate(data.dateRestitution)}</span> à destination de <span style="font-weight: 900">${data.destination}</span> à été effectué et est en attente de réponse.</p><br/><p style="font-size: 20px">À bientôt.</p>`,
                };
                await sendMail(mailOptions)
            } else {
                openModal(responseData.error, 'error');
                throw new Error(`Une erreur s'est produite lors de la création de ${what}`);
            }
        } catch (error) {
            console.error(`Erreur lors de la création de ${what}:`, error);
        }
    };

    return (
        <section className='flex fdc'>
            <h2>Demander une réservation</h2>
            <Form fields={fields} onSubmit={handleSubmit(handleFormSubmit)} register={register} errors={errors} />
            <section className="flex fdc annuler-btn">
                <button className='pointer annuler' onClick={closeForm}>Annuler</button>
            </section>
        </section>
    );
};

export default CreateEvent;