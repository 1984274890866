const ValidateFlux = ({ event }) => {
    const convertDate = (date) => {
        return new Date(date).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric", }).replace(/\//g, '-')
    }
    return (
        <section className="content-validate flex">
            <p className="flex">
                <span className="user">{event.name} {event.firstname} </span>
                - {event.phone} :
            </p>
            <p className="flex">
                <span>{convertDate(event.dateLocation)}</span>
                <img src="../img/icon/right-arrow.png" alt={`jusqu'au`} className="icon" />
                <span>{convertDate(event.dateRestitution)}</span>
            </p>
            <p className="flex">
                <span>{event.kilomprev}km</span>
                <span>{event.destination}</span>
            </p>
        </section>
    );
};

export default ValidateFlux;