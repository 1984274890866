import { useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
// Components
import { AuthContext } from './AuthProvider'
import UpdateButton from './Action/UpdateButton';
import DeleteButton from './Action/DeleteButton';
import Image from './Image';
// Api
import { getData } from '../api/api'
// Styles
import '../styles/Vehicules.css'

const Vehicules = () => {
    const { id } = useParams();
    const { user } = useContext(AuthContext);
    const [vehicules, setVehicules] = useState([]);

    useEffect(() => {
        const fetchVehicules = async () => {
            try {
                const vehicules = await getData(`vehicules/categories/${id}`);
                setVehicules(vehicules);
            } catch (error) {
                console.error('Une erreur s\'est produite :', error);
            }
        };

        fetchVehicules();
    }, [id]);

    const handleDelete = (id) => {
        setVehicules((prevData) => prevData.filter((item) => item.id !== id))
    }

    return (
        <section className='vehicules list flex'>
            {vehicules.length > 0 ? (
                vehicules.map((v) => (
                    <article key={v.id} className='flex pointer'>
                        <Link to={`/events/vehicule/${v.id}`} className='flex linkVeh'>
                            <Image imageUrl={v.avatar} />
                            <section className='flex'>
                                <p>{v.modele}</p>
                                -
                                <p>{v.marque}</p>
                            </section>
                        </Link>
                        {user && user.admin ?
                            <aside>
                                <UpdateButton id={v.id} what={'vehicules'} />
                                <DeleteButton id={v.id} onDelete={handleDelete} what={'vehicules'} />
                            </aside>
                            : ''}
                    </article>
                ))
            ) : (
                <p>Aucun véhicule disponible</p>
            )}
        </section>
    );
};

export default Vehicules;