import { useContext } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
// Pages
import Home from './pages/Home';
import Admin from './pages/Admin';
// Components
import Nav from './components/Nav';
import Vehicules from './components/Vehicules';
import Update from './components/Update';
import MyCalendar from './components/MyCalendar';
import Disconnect from './components/Action/Disconnect';
import Sign from './components/Sign';
import Contract from './components/Contract';
import Alert from './components/Action/Alert';
//      .Context
import { AuthContext } from './components/AuthProvider';
//      .Admin
import Agenda from './components/Agenda'
import Validate from './components/Validate'
import Add from './components/Add';
import Create from './components/Create';
//      .Footer
import Mentions from './pages/Mentions';
import Cookies from './pages/Cookies';
// Data
import formData from './data/FormData';
// Style
import './styles/General.css'
import logo from './images/logo-wbg.png'

const App = () => {
    const { user } = useContext(AuthContext);

    return (
        <>
            <Alert />
            <section className='nav-principal flex'>
                <section className='flex'>
                    <NavLink to={'/'} className='logo flex'>
                        <img src={logo} alt='Logo Boulevard Auto' />
                    </NavLink>
                    {user && user.admin &&
                        <section className='contain-nav-principal flex'>
                            <Nav what={'principal'} />
                            <Disconnect />
                        </section>
                    }
                </section>
            </section>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='*' element={<Home />} />
                <Route path="/categorie/:id" element={<Vehicules />} />
                <Route path="/update/:what/:id" element={<Update />} />
                <Route path="/events/:what/:id" element={<MyCalendar />} />
                <Route path="/sign" element={<Sign />} />
                <Route path="/contrat/:id" element={<Contract />} />
                {/* Admin */}
                {user && user.admin &&
                    <Route path='admin' element={<Admin />}>
                        <Route path="agenda" element={<Agenda />} />
                        <Route path="valider" element={<Validate />} />
                        <Route path="ajouter" element={<Add />}>
                            <Route path="categorie" element={<Create fields={formData.categories} what={'categories'} title='Ajouter une catégorie' />} />
                            <Route path="vehicule" element={<Create fields={formData.vehicules} what={'vehicules'} title='Ajouter un véhicule' />} />
                        </Route>
                    </Route>
                }
                {/* Footer */}
                <Route path="cookies" element={<Cookies />} />
                <Route path="mentions" element={<Mentions />} />
            </Routes>
            {/* <Nav what={'footer'} /> */}
        </>
    );
};

export default App;