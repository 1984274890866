import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
// Style
import '../styles/Download.css';
// Images
import state from '../images/state.png';
import logo from '../images/logo.jpg';

Font.register({ family: 'Mont', src: '/font/Monserrat/Montserrat-Light.ttf', });
Font.register({ family: 'Mont-Semi', src: '/font/Monserrat/Montserrat-SemiBold.ttf', });
Font.register({ family: 'Mont-Bold', src: '/font/Monserrat/Montserrat-Bold.ttf', });

const styles = StyleSheet.create({
    page: { padding: '5px 10px' },
    padsection: { padding: '0 10px', },
    pagechild: { border: '1px solid #5F5566', margin: '5px' },
    section: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '3px 0', fontSize: '9px', fontFamily: 'Mont-Semi' },
    sectiont: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-end', fontSize: '9px' },
    sectionde: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' },
    deplacement: { width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' },
    textdepl: { display: 'flex', justifyContent: 'space-between' },
    vehicule: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%' },
    veh: { borderBottom: '1px solid #5F5566' },
    containdepl: { width: '100%', padding: '10px' },
    text: { width: 'auto', minWidth: '30px', fontSize: '9px', textAlign: 'left', fontFamily: 'Mont', borderBottom: '1px solid #5F5566' },
    title: { fontSize: '13px', width: '100%', backgroundColor: '#022B3A', color: 'white', textAlign: 'center', textTransform: 'uppercase', fontFamily: 'Mont-Semi', borderBottom: '1px solid #5F5566' },
    stitle: { fontSize: '15px', width: '100%', backgroundColor: '#022B3A', color: 'white', textAlign: 'center', textTransform: 'uppercase', fontFamily: 'Mont-Bold', border: '1px solid #5F5566', margin: '2px 0', padding: '2px 0' },
    infos: { fontSize: '10px', color: '#5B616A', textAlign: 'center', margin: '5px 0' },
    infosB: { fontSize: '8px', color: '#5B616A', textAlign: 'left', margin: '0', textTransform: 'uppercase' },
    imageState: { height: '80px', objectFit: 'contain' },
});

const Download = ({ data }) => {

    return (
        <Document title={`${data.nom} - ${data.prenom}`} author='Boulevard Auto'>
            <Page size="A4" style={styles.page} >
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={[styles.pagechild, { width: '30%', border: '0', marginTop: '0' }]}>
                        <Image src={logo} style={{ objectFit: 'contain', height: '30px', }} />
                        <Text style={[styles.infosB]}>Boulevard Auto</Text>
                        <Text style={[styles.infosB]}>B.A.DEVELOPPEMENT</Text>
                        <Text style={[styles.infosB]}>Siret 83497604500015</Text>
                        <Text style={[styles.infosB]}>TVA FR50834976045</Text>
                        <Text style={[styles.infosB]}>Z.A. du Bourny - 206 Bd Jean Jaurès</Text>
                        <Text style={[styles.infosB]}>53000 LAVAL</Text>
                        <Text style={[styles.infosB]}>Tél. 02.43.69.08.08 - Fax 02.43.69.20.80</Text>
                        <Text style={[styles.infosB]}>E-mail : boulevardauto53@gmail.com</Text>
                    </View>
                    <View style={{ width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <View style={[styles.pagechild, { display: 'flex', justifyContent: 'center', width: '50%', border: 'none', marginTop: '0', textAlign: 'center' }]}>
                                <Text style={{ fontSize: '10px', textTransform: 'uppercase' }}>Magasin</Text>
                                <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>Pièces détachées</Text>
                                <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>Lubrifiants</Text>
                                <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>Pneumatiques</Text>
                            </View>
                            <View style={[styles.pagechild, { display: 'flex', justifyContent: 'center', width: '50%', border: 'none', marginTop: '0', textAlign: 'center' }]}>
                                <Text style={{ fontSize: '10px', textTransform: 'uppercase' }}>Atelier auto</Text>
                                <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>Entretien Auto</Text>
                                <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>avec ou sans</Text>
                                <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>rendez-vous</Text>
                            </View>
                        </View>
                        <Text style={[styles.text, { textTransform: 'uppercase', fontFamily: 'Mont-Semi', borderBottom: '0' }]}>Vente de véhicules occasions</Text>
                    </View>
                </View >
                <Text style={[styles.stitle]}>CONTRAT DE LOCATION DE VÉHICULE</Text>
                <View style={styles.pagechild}>
                    <Text style={styles.title}>renseignements relatifs au(x) conducteur(s)</Text>
                    <View style={[styles.section, styles.padsection]}>
                        <View style={styles.sectiont}>
                            <Text>Nom: </Text>
                            <Text style={[styles.text]}>{data.nom}</Text>
                        </View>
                        <View style={styles.sectiont}>
                            <Text>Prénom: </Text>
                            <Text style={[styles.text]}>{data.prenom}</Text>
                        </View>
                        <View style={styles.sectiont}>
                            <Text>Né(e) le: </Text>
                            <Text style={[styles.text]}>{data.naissance}</Text>
                        </View>
                        <View style={styles.sectiont}>
                            <Text>à </Text>
                            <Text style={[styles.text]}> {data.lieu_naissance} </Text>
                        </View>
                    </View>
                    <View style={[styles.section, styles.padsection]}>
                        <View style={[styles.sectiont, { width: '72%' }]}>
                            <Text>Adresse : </Text>
                            <Text style={[styles.text]}> {data.adresse} </Text>
                        </View>
                        <View style={[styles.sectiont, { width: '27%' }]}>
                            <Text>Tél. : </Text>
                            <Text style={[styles.text]}>{data.tel}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, styles.padsection]}>
                        <View style={[styles.sectiont, { width: '35%' }]}>
                            <Text>N° Permis : </Text>
                            <Text style={[styles.text]}> {data.num_permis} </Text>
                        </View>
                        <View style={[styles.sectiont, { width: '20%' }]}>
                            <Text>délivré le </Text>
                            <Text style={[styles.text]}> {data.delivrance} </Text>
                        </View>
                        <View style={[styles.sectiont, { width: '43%' }]}>
                            <Text>par la Préfecture de </Text>
                            <Text style={[styles.text]}> {data.prefecture} </Text>
                        </View>
                    </View>
                    <Text style={styles.title}>Autre conducteur</Text>
                    <View style={[styles.section, styles.padsection]}>
                        <View style={styles.sectiont}>
                            <Text>Nom: </Text>
                            <Text style={[styles.text]}> {data.nom_d} </Text>
                        </View>
                        <View style={styles.sectiont}>
                            <Text>Prénom: </Text>
                            <Text style={[styles.text]}> {data.prenom_d} </Text>
                        </View>
                        <View style={styles.sectiont}>
                            <Text>Né(e) le: </Text>
                            <Text style={[styles.text]}> {data.naissance_d} </Text>
                        </View>
                        <View style={styles.sectiont}>
                            <Text>à </Text>
                            <Text style={[styles.text]}> {data.lieu_naissance_d} </Text>
                        </View>
                    </View>
                    <View style={[styles.section, styles.padsection]}>
                        <View style={[styles.sectiont, { width: '35%' }]}>
                            <Text>N° Permis : </Text>
                            <Text style={[styles.text]}> {data.num_permis_d} </Text>
                        </View>
                        <View style={[styles.sectiont, { width: '20%' }]}>
                            <Text>délivré le </Text>
                            <Text style={[styles.text]}> {data.delivrance_d} </Text>
                        </View>
                        <View style={[styles.sectiont, { width: '43%' }]}>
                            <Text>par la Préfecture de </Text>
                            <Text style={[styles.text]}> {data.prefecture_d} </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.pagechild}>
                    <Text style={styles.title}>vehicule en location</Text>
                    <View style={[styles.section]}>
                        <View style={[styles.sectiont, styles.vehicule, { width: '23.5%' }]}>
                            <Text style={styles.veh}>Marque</Text>
                            <Text style={[styles.text]}> {data.marque} </Text>
                        </View>
                        <View style={[styles.sectiont, styles.vehicule, { width: '23.5%' }]}>
                            <Text style={styles.veh}>Modèle</Text>
                            <Text style={[styles.text]}> {data.modele} </Text>
                        </View>
                        <View style={[styles.sectiont, styles.vehicule, { width: '23.5%' }]}>
                            <Text style={styles.veh}>N° Immatriculation</Text>
                            <Text style={[styles.text]}> {data.immat}</Text>
                        </View>
                        <View style={[styles.sectiont, styles.vehicule, { width: '23.5%' }]}>
                            <Text style={styles.veh}>Carburant</Text>
                            <Text style={[styles.text]}> {data.carburant} </Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.section, styles.sectionde, styles.pagechild]}>
                    <View style={styles.deplacement}>
                        <Text style={styles.title}>Départ</Text>
                        <View style={styles.containdepl}>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Date de départ : </Text>
                                <Text style={[styles.text]}> {data.date_depart} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Date du retour prévu : </Text>
                                <Text style={[styles.text]}> {data.date_retour_prevue} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Heure : </Text>
                                <Text style={[styles.text]}> {data.heure_depart} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Km au compteur :</Text>
                                <Text style={[styles.text]}> {data.kilom_compteur && `${data.kilom_compteur} km`}</Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Niveau de carburant : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.niv_carburant} </Text>
                            </View>
                            <Text style={styles.infos}>Le carburant reste à la charge du conducteur</Text>
                            <Text style={[styles.infos, { textDecoration: 'underline', textTransform: 'uppercase', fontFamily: 'Mont-Bold' }]}>État du véhicule au départ</Text>
                            <Image src={state} style={styles.imageState} />
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', textAlign: 'center', margin: '3px 0' }]}>Rayé Enfoncé Cassé</Text>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Photocopie de la Carte Grise : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.carte_grise} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Attestation d'assurance : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.attest_assurance} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Kit de sécurité : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.kit_secu} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Disque de stationnement européen : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.dis_stat_eur} </Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text>Observations :</Text>
                                <Text style={[styles.text, { borderBottom: '0', textDecoration: 'underline' }]}>{data.observations}</Text>
                            </View>
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginTop: '10px' }]}>Je déclare avoir pris connaissance de l'état du véhicule prêté.</Text>
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px' }]}>En cas d'accident, ou de dégâts occasionnés à ce véhicule, j'accepte de régler  une franchise de 1 250€.</Text>
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginBottom: '10px' }]}>Je m'engage à régler toutes les infractions éventuelles au Code de la Route,  générées par ma conduite, et à respecter le véhicule, ainsi que les accessoires.</Text>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Fait (en double exemplaire) à : </Text>
                                <Text style={[styles.text]}> {data.fait} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>le : </Text>
                                <Text style={[styles.text]}> {data.date_fait} </Text>
                            </View>
                            <View style={[styles.sectiont, { marginTop: '10px' }]}>
                                <Text style={{ width: '50%', textDecoration: 'underline' }}>L'utilisateur</Text>
                                <Text style={{ width: '50%', textDecoration: 'underline' }}>Le Prêteur</Text>
                            </View>
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginBottom: '50px' }]}>(Écrire "Lu et approuvé et signer)</Text>
                        </View>
                    </View>
                    <View style={[styles.deplacement]}>
                        <Text style={[styles.title, { borderLeft: '1px solid #5F5566' }]}>Retour</Text>
                        <View style={styles.containdepl}>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Date de retour : </Text>
                                <Text style={[styles.text]}> {data.date_retour} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Heure : </Text>
                                <Text style={[styles.text]}> {data.heure_retour} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Km au compteur :</Text>
                                <Text style={[styles.text]}> {data.kilom_compteur_retour && `${data.kilom_compteur_retour} km`}</Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Niveau de carburant : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.niv_carburant_r} </Text>
                            </View>
                            <Text style={[styles.infos, { marginTop: '17px' }]}>Le carburant reste à la charge du conducteur</Text>
                            <Text style={[styles.infos, { textDecoration: 'underline', textTransform: 'uppercase', fontFamily: 'Mont-Bold' }]}>État du véhicule au retour</Text>
                            <Image src={state} style={styles.imageState} />
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', textAlign: 'center', margin: '3px 0' }]}>Rayé Enfoncé Cassé</Text>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Photocopie de la Carte Grise : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.carte_grise_r} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Attestation d'assurance : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.attest_assurance_r} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Kit de sécurité : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.kit_secu_r} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Disque de stationnement européen : </Text>
                                <Text style={[styles.text, { minWidth: '0' }]}> {data.dis_stat_eur_r} </Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text>Observations :</Text>
                                <Text style={[styles.text, { borderBottom: '0', textDecoration: 'underline' }]}>{data.observations_r}</Text>
                            </View>
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginTop: '10px' }]}>Je déclare avoir pris connaissance de l'état du véhicule prêté.</Text>
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px' }]}>En cas d'accident, ou de dégâts occasionnés à ce véhicule, j'accepte de régler  une franchise de 1 250€.</Text>
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginBottom: '10px' }]}>Je m'engage à régler toutes les infractions éventuelles au Code de la Route,  générées par ma conduite, et à respecter le véhicule, ainsi que les accessoires.</Text>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>Fait (en double exemplaire) à : </Text>
                                <Text style={[styles.text]}> {data.fait_r} </Text>
                            </View>
                            <View style={[styles.sectiont, styles.textdepl]}>
                                <Text>le : </Text>
                                <Text style={[styles.text]}> {data.date_fait_r} </Text>
                            </View>
                            <View style={[styles.sectiont, { marginTop: '10px' }]}>
                                <Text style={{ width: '50%', textDecoration: 'underline' }}>L'utilisateur</Text>
                                <Text style={{ width: '50%', textDecoration: 'underline' }}>Le Prêteur</Text>
                            </View>
                            <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginBottom: '50px' }]}>(Écrire "Lu et approuvé et signer)</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
        // <PDFViewer height={2000} width={1500}>
        //     <Document>
        //         <Page size="A4" style={styles.page}>
        //             <View style={{ display: 'flex', flexDirection: 'row' }}>
        //                 <View style={[styles.pagechild, { width: '30%', border: 'none', marginTop: '0' }]}>
        //                     <Image src={logo} style={{ objectFit: 'contain', height: '30px', }} />
        //                     <Text style={[styles.infosB]}>Boulevard Auto</Text>
        //                     <Text style={[styles.infosB]}>B.A.DEVELOPPEMENT</Text>
        //                     <Text style={[styles.infosB]}>Siret 83497604500015</Text>
        //                     <Text style={[styles.infosB]}>TVA FR50834976045</Text>
        //                     <Text style={[styles.infosB]}>Z.A. du Bourny - 206 Bd Jean Jaurès</Text>
        //                     <Text style={[styles.infosB]}>53000 LAVAL</Text>
        //                     <Text style={[styles.infosB]}>Tél. 02.43.69.08.08 - Fax 02.43.69.20.80</Text>
        //                     <Text style={[styles.infosB]}>E-mail : boulevardauto53@gmail.com</Text>
        //                 </View>
        //                 <View style={{ width: '66%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        //                     <View style={{ display: 'flex', flexDirection: 'row' }}>
        //                         <View style={[styles.pagechild, { display: 'flex', justifyContent: 'center', width: '50%', border: 'none', marginTop: '0', textAlign: 'center' }]}>
        //                             <Text style={{ fontSize: '10px', textTransform: 'uppercase' }}>Magasin</Text>
        //                             <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>Pièces détachées</Text>
        //                             <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>Lubrifiants</Text>
        //                             <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>Pneumatiques</Text>
        //                         </View>
        //                         <View style={[styles.pagechild, { display: 'flex', justifyContent: 'center', width: '50%', border: 'none', marginTop: '0', textAlign: 'center' }]}>
        //                             <Text style={{ fontSize: '10px', textTransform: 'uppercase' }}>Atelier auto</Text>
        //                             <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>Entretien Auto</Text>
        //                             <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>avec ou sans</Text>
        //                             <Text style={[styles.text, { textAlign: 'center', borderBottom: '0' }]}>rendez-vous</Text>
        //                         </View>
        //                     </View>
        //                     <Text style={[styles.text, { textTransform: 'uppercase', fontFamily: 'Mont-Semi', borderBottom: '0' }]}>Vente de véhicules occasions</Text>
        //                 </View>
        //             </View>
        //             <Text style={[styles.stitle]}>CONTRAT DE LOCATION DE VÉHICULE</Text>
        //             <View style={styles.pagechild}>
        //                 <Text style={styles.title}>renseignements relatifs au(x) conducteur(s)</Text>
        //                 <View style={[styles.section, styles.padsection]}>
        //                     <View style={styles.sectiont}>
        //                         <Text>Nom: </Text>
        //                         <Text style={[styles.text]}>frzfre</Text>
        //                     </View>
        //                     <View style={styles.sectiont}>
        //                         <Text>Prénom: </Text>
        //                         <Text style={[styles.text]}>rgegtegrt</Text>
        //                     </View>
        //                     <View style={styles.sectiont}>
        //                         <Text>Né(e) le: </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={styles.sectiont}>
        //                         <Text>à </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                 </View>
        //                 <View style={[styles.section, styles.padsection]}>
        //                     <View style={[styles.sectiont, { width: '72%' }]}>
        //                         <Text>Adresse : </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={[styles.sectiont, { width: '27%' }]}>
        //                         <Text>Tél. : </Text>
        //                         <Text style={[styles.text]}> 09 09 09 09 09 </Text>
        //                     </View>
        //                 </View>
        //                 <View style={[styles.section, styles.padsection]}>
        //                     <View style={[styles.sectiont, { width: '30%' }]}>
        //                         <Text>N° Permis : </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={[styles.sectiont, { width: '20%' }]}>
        //                         <Text>délivré le </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={[styles.sectiont, { width: '45%' }]}>
        //                         <Text>par la Préfecture de </Text>
        //                         <Text style={[styles.text]}> fregr </Text>
        //                     </View>
        //                 </View>
        //                 <Text style={styles.title}>Autre conducteur</Text>
        //                 <View style={[styles.section, styles.padsection]}>
        //                     <View style={styles.sectiont}>
        //                         <Text>Nom: </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={styles.sectiont}>
        //                         <Text>Prénom: </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={styles.sectiont}>
        //                         <Text>Né(e) le: </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={styles.sectiont}>
        //                         <Text>à </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                 </View>
        //                 <View style={[styles.section, styles.padsection]}>
        //                     <View style={[styles.sectiont, { width: '72%' }]}>
        //                         <Text>Adresse : </Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={[styles.sectiont, { width: '27%' }]}>
        //                         <Text>Tél. : </Text>
        //                         <Text style={[styles.text]}> 09 09 09 09 09 </Text>
        //                     </View>
        //                 </View>
        //             </View>
        //             <View style={styles.pagechild}>
        //                 <Text style={styles.title}>vehicule en location</Text>
        //                 <View style={[styles.section]}>
        //                     <View style={[styles.sectiont, styles.vehicule, { width: '23.5%' }]}>
        //                         <Text style={styles.veh}>Marque</Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={[styles.sectiont, styles.vehicule, { width: '23.5%' }]}>
        //                         <Text style={styles.veh}>Modèle</Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={[styles.sectiont, styles.vehicule, { width: '23.5%' }]}>
        //                         <Text style={styles.veh}>N° Immatriculation</Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                     <View style={[styles.sectiont, styles.vehicule, { width: '23.5%' }]}>
        //                         <Text style={styles.veh}>Carburant</Text>
        //                         <Text style={[styles.text]}> dedefeger </Text>
        //                     </View>
        //                 </View>
        //             </View>
        //             <View style={[styles.section, styles.sectionde, styles.pagechild]}>
        //                 <View style={styles.deplacement}>
        //                     <Text style={styles.title}>Départ</Text>
        //                     <View style={styles.containdepl}>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Date de départ : </Text>
        //                             <Text style={[styles.text]}> dedefeger </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Date du retour prévu : </Text>
        //                             <Text style={[styles.text]}> dedefeger </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Heure : </Text>
        //                             <Text style={[styles.text]}> dedefeger </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Km au compteur :</Text>
        //                             <Text style={[styles.text]}> dedefeger km</Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Niveau de carburant : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}> dedefeger </Text>
        //                         </View>
        //                         <Text style={styles.infos}>Le carburant reste à la charge du conducteur</Text>
        //                         <Text style={[styles.infos, { textDecoration: 'underline', textTransform: 'uppercase', fontFamily: 'Mont-Bold' }]}>État du véhicule au départ</Text>
        //                         <Image src={state} style={styles.imageState} />
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', textAlign: 'center' }]}>Rayé Enfoncé Cassé</Text>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Photocopie de la Carte Grise : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}>oui</Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Attestation d'assurance : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}>oui</Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Kit de sécurité : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}>oui</Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Disque de stationnement européen : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}>oui</Text>
        //                         </View>
        //                         <View style={{ display: 'flex', flexDirection: 'column', height: '45px', overflow: 'hidden' }}>
        //                             <Text>Observations :</Text>
        //                             <Text style={[styles.text, { borderBottom: '0', textDecoration: 'underline' }]}>
        //                                 Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, quos earum excepturi odit, ipsa possimus delectus, el
        //                             </Text>
        //                         </View>
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginTop: '10px' }]}>Je déclare avoir pris connaissance de l'état du véhicule prêté.</Text>
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px' }]}>En cas d'accident, ou de dégâts occasionnés à ce véhicule, j'accepte de régler  une franchise de 1 250€.</Text>
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginBottom: '10px' }]}>Je m'engage à régler toutes les infractions éventuelles au Code de la Route,  générées par ma conduite, et à respecter le véhicule, ainsi que les accessoires.</Text>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Fait (en double exemplaire) à : </Text>
        //                             <Text style={[styles.text]}> Laval </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>le : </Text>
        //                             <Text style={[styles.text]}> 18/05/2002 </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, { marginTop: '10px' }]}>
        //                             <Text style={{ width: '50%', textDecoration: 'underline' }}>L'utilisateur</Text>
        //                             <Text style={{ width: '50%', textDecoration: 'underline' }}>Le Prêteur</Text>
        //                         </View>
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginBottom: '50px' }]}>(Écrire "Lu et approuvé et signer)</Text>
        //                     </View>
        //                 </View>
        //                 <View style={[styles.deplacement]}>
        //                     <Text style={[styles.title, { borderLeft: '1px solid #5F5566' }]}>Retour</Text>
        //                     <View style={styles.containdepl}>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Date de retour : </Text>
        //                             <Text style={[styles.text]}> dedefeger </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Heure : </Text>
        //                             <Text style={[styles.text]}> dedefeger </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Km au compteur :</Text>
        //                             <Text style={[styles.text]}> dedefeger km</Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Niveau de carburant : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}> dedefeger </Text>
        //                         </View>
        //                         <Text style={[styles.infos, { marginTop: '17px' }]}>Le carburant reste à la charge du conducteur</Text>
        //                         <Text style={[styles.infos, { textDecoration: 'underline', textTransform: 'uppercase', fontFamily: 'Mont-Bold' }]}>État du véhicule au retour</Text>
        //                         <Image src={state} style={styles.imageState} />
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', textAlign: 'center'}]}>Rayé Enfoncé Cassé</Text>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Photocopie de la Carte Grise : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}>oui</Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Attestation d'assurance : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}>oui</Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Kit de sécurité : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}>oui</Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Disque de stationnement européen : </Text>
        //                             <Text style={[styles.text, { minWidth: '0' }]}>oui</Text>
        //                         </View>
        //                         <View style={{ display: 'flex', flexDirection: 'column', height: '45px', overflow: 'hidden' }}>
        //                             <Text>Observations :</Text>
        //                             <Text style={[styles.text, { borderBottom: '0', textDecoration: 'underline' }]}>
        //                                 Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, quos earum excepturi odit, ipsa possimus delectus, el
        //                             </Text>
        //                         </View>
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginTop: '10px' }]}>Je déclare avoir pris connaissance de l'état du véhicule prêté.</Text>
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px' }]}>En cas d'accident, ou de dégâts occasionnés à ce véhicule, j'accepte de régler  une franchise de 1 250€.</Text>
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginBottom: '10px' }]}>Je m'engage à régler toutes les infractions éventuelles au Code de la Route,  générées par ma conduite, et à respecter le véhicule, ainsi que les accessoires.</Text>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>Fait (en double exemplaire) à : </Text>
        //                             <Text style={[styles.text]}> Laval </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, styles.textdepl]}>
        //                             <Text>le : </Text>
        //                             <Text style={[styles.text]}> 18/05/2002 </Text>
        //                         </View>
        //                         <View style={[styles.sectiont, { marginTop: '10px' }]}>
        //                             <Text style={{ width: '50%', textDecoration: 'underline' }}>L'utilisateur</Text>
        //                             <Text style={{ width: '50%', textDecoration: 'underline' }}>Le Prêteur</Text>
        //                         </View>
        //                         <Text style={[styles.text, { borderBottom: '0', fontSize: '7px', marginBottom: '50px' }]}>(Écrire "Lu et approuvé et signer)</Text>
        //                     </View>
        //                 </View>
        //             </View>
        //         </Page>
        //     </Document>
        // </PDFViewer >
    );
};

export default Download;
