import { useEffect } from 'react';
// Styles
import '../styles/ConfirmationModal.css'

const ConfirmationModal = ({ onConfirm, onCancel, message }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        };
    }, [])

    return (
        <div className="modal flex">
            <div className="modal-content">
                <h3>Confirmer</h3>
                <p>{message}</p>
                <div className="modal-actions">
                    <button onClick={onCancel} className="modal-cancel pointer">Annuler</button>
                    <button onClick={onConfirm} className="modal-confirm pointer">Confirmer</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
