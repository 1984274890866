import { useContext } from 'react';
import { AlertContext } from "../AlertContext";
// Styles
import '../../styles/Alert.css'

const Alert = () => {
    const { alerts, closeModal } = useContext(AlertContext);

    return (
        <section className='alerts flex'>
            {alerts.map((a, i) => (
                <section key={i} className={`contain-alert flex ${a.type ? a.type : 'success'}`}>
                    <aside className='alert pointer' onClick={() => closeModal(i)}>
                        <p>{a.message}</p>
                    </aside>
                </section>
            ))}
        </section>
    );
};

export default Alert;
