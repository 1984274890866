export default function Field({ name, type, label, children, register, validation, errors }) {
    return (
        <>
            <label htmlFor={name}>
                {label}
            </label>
            <input type={type} id={name} {...register(name, validation)} {...children} />
            <p role="alert">{errors[name] && errors[name].message}</p>
        </>
    )
}