import React, { useEffect, useState } from 'react';

const Image = ({ imageUrl }) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const src = URL.createObjectURL(blob);
                setImageSrc(src);

            } catch (error) {
                console.error('Erreur lors du chargement de l\'image', error);
            }
        };

        if (imageUrl) {
            fetchImage();
        }

        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, []);

    return imageSrc ? <img src={imageSrc} alt="" crossOrigin="anonymous" /> : null;
};

export default Image;